@xs: 576px;
@sm: 768px;
@md: 992px;
@lg: 1200px;
@xl: 1600px;

@Blink-Color: #ebe50b;

@keyframes move-text {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
